(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/hero/views/hero-cube.js') >= 0) return;  svs.modules.push('/components/lb-ui/hero/views/hero-cube.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.lb_ui=_svs.lb_ui||{};
_svs.lb_ui.hero=_svs.lb_ui.hero||{};
_svs.lb_ui.hero.templates=_svs.lb_ui.hero.templates||{};
svs.lb_ui.hero.templates.hero_cube = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"hero-brand-cube\">\n  <div class=\"brand-cube"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cube") : depth0)) != null ? lookupProperty(stack1,"productName") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":24},"end":{"line":3,"column":87}}})) != null ? stack1 : "")
    + "\">\n    <div\n      class=\"brand-cube-faces\"\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"cube") : depth0)) != null ? lookupProperty(stack1,"productId") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":63}}})) != null ? stack1 : "")
    + "\n    >\n      <div class=\"brand-cube-face brand-cube-front\"></div>\n      <div class=\"brand-cube-face brand-cube-back\"></div>\n      <div class=\"brand-cube-face brand-cube-right\"></div>\n      <div class=\"brand-cube-face brand-cube-left\"></div>\n      <div class=\"brand-cube-face brand-cube-top\"></div>\n      <div class=\"brand-cube-face brand-cube-bottom\"></div>\n    </div>\n  </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " brand-cube-"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cube") : depth0)) != null ? lookupProperty(stack1,"productName") : stack1), depth0));
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-id=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"cube") : depth0)) != null ? lookupProperty(stack1,"productId") : stack1), depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"cube") : depth0)) != null ? lookupProperty(stack1,"productId") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":17,"column":7}}})) != null ? stack1 : "");
},"useData":true});
Handlebars.partials['components-lb_ui-hero-hero_cube'] = svs.lb_ui.hero.templates.hero_cube;
})(svs, Handlebars);


 })(window);